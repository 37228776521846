// CheckoutContext.js
import React, { createContext, useState, useEffect } from 'react';

const CheckoutContext = createContext();

const CheckoutProvider = ({ children }) => {
  const [checkoutOpen, setCheckoutOpen] = useState(() => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('checkoutOpen') === 'true'
    }
    return false
  });
  const [uiLocation, setUiLocation] = useState(() => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('uiLocation') || 'dummy'
    }
    return 'dummy'
  });
  const [quantity, setQuantity] = useState(() => {
    if (typeof window !== 'undefined') {
      return parseInt(localStorage.getItem('quantity') || '5')
    }
    return 5
  });
  const [paymentMode, setPaymentMode] = useState(() => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('paymentMode') || 'payment'
    }
    return 'payment'
  });
  const [subscribeClientSecret, setSubscribeClientSecret] = useState(null);
  const [paymentClientSecret, setPaymentClientSecret] = useState(null);
  const [switchCheckout, setSwitchCheckout] = useState(false);
  const [weeklyClientSecret, setWeeklyClientSecret] = useState(null);
  const [unauthCheckout, setUnauthCheckout] = useState(() => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('unauthCheckout') === 'true'
    }
    return false
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('checkoutOpen', checkoutOpen)
      localStorage.setItem('uiLocation', uiLocation)
      localStorage.setItem('quantity', quantity)
      localStorage.setItem('paymentMode', paymentMode)
      localStorage.setItem('unauthCheckout', unauthCheckout)
    }
  }, [checkoutOpen, uiLocation, quantity, paymentMode, unauthCheckout]);

  return (
    <CheckoutContext.Provider value={{ 
      checkoutOpen, 
      setCheckoutOpen, 
      uiLocation, 
      setUiLocation, 
      quantity, 
      setQuantity, 
      paymentMode, 
      setPaymentMode, 
      subscribeClientSecret, 
      setSubscribeClientSecret, 
      paymentClientSecret, 
      setPaymentClientSecret, 
      weeklyClientSecret, 
      setWeeklyClientSecret, 
      switchCheckout, 
      setSwitchCheckout,
      unauthCheckout,
      setUnauthCheckout
    }}>
      {children}
    </CheckoutContext.Provider>
  );
};

export { CheckoutProvider, CheckoutContext };