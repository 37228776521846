import React from 'react';
import NextLink from 'next/link';
import dynamic from 'next/dynamic';
import Image from 'next/image';

const Navbar = React.memo(function Navbar(props) {    
    const NavbarCTA = dynamic(() => import('./navbarcta'), {
      loading: () => <button className="animate-pulse btn btn-sm rounded-full border-white text-white px-4 skeleton">Loading</button>
    })

    const NavbarUserExtras = dynamic(() => import('./navbaruserextras'))
    return (
        <>
        {/* {page == "face_analyzer/facial_feature" ? 
        <div className="bg-black w-full p-2 text-center text-sm text-white">
          🚨 This feature is free for a limited time.  
          <NextLink prefetch={false} href="https://www.reddit.com/r/stylesense/comments/154uocq/about_the_new_eye_shape_analyzer_and_upcoming/" className="link ml-2">Read more <span className={`${inter.className}`}>-&gt;</span> </NextLink>
        </div> : null        
        }        */}
        <div id="navbar" className={`navbar bg-transparent z-[500] ${props.showDropdown ? "pb-0" : ""}`}>          
          <div className="navbar-start w-fit mr-2 md:w-[75%]">
            <div className="dropdown md:hidden">
              {/* <label tabIndex={0} className="btn btn-ghost">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
              </label>
              <ul tabIndex={0} className="w-max md:hidden menu menu-compact dropdown-content mt-1 shadow bg-base-100 rounded-box w-52 text-sm">                      
                <li>
                  <details open>
                    <summary>Face Analysis AI</summary>
                    <ul>
                      <li><NextLink prefetch={false} href="/face_analyzer">Face Shape Analyzer</NextLink></li>
                      <li><NextLink prefetch={false} href="/face_analyzer/eye_shape">Eye Shape Analyzer <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 24 24" className="ml-2"><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm4.24 16L12 15.45 7.77 18l1.12-4.81-3.73-3.23 4.92-.42L12 5l1.92 4.53 4.92.42-3.73 3.23L16.23 18z"/></svg></NextLink></li>
                    </ul>
                  </details>
                </li>
                <li>
                  <details open>
                    <summary>Makeup Guidance</summary>
                    <ul>
                      <li><NextLink prefetch={false} href="/makeup_recommendations/face_contour">Face Contour Guide</NextLink></li>
                      <li><NextLink prefetch={false} href="/makeup_recommendations/nose_contour">Nose Contour Guide <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 24 24" className="ml-2"><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm4.24 16L12 15.45 7.77 18l1.12-4.81-3.73-3.23 4.92-.42L12 5l1.92 4.53 4.92.42-3.73 3.23L16.23 18z"/></svg></NextLink></li>
                      <li><NextLink prefetch={false} href="/makeup_recommendations/blush_placement">Blush Placement Guide</NextLink></li>
                      <li><NextLink prefetch={false} href="/makeup_recommendations/eyebrows">Eyebrow Shaping Guide</NextLink></li>
                      <li><NextLink prefetch={false} href="/makeup_recommendations/eyeliner">Eyeliner Guide</NextLink></li>                                            
                    </ul>
                  </details>
                </li>                                
                <li>
                  <details open>
                    <summary>Learn</summary>
                    <ul>
                      <li><NextLink prefetch={false} href="/help">Help Center</NextLink></li>
                      <li><NextLink prefetch={false} href="/blog">Blog</NextLink></li>
                    </ul>
                  </details>
                </li>      
                <li><NextLink prefetch={false} href="/pricing">Pricing</NextLink></li>   
              </ul> */}
              <div tabIndex={0} role="button" className="btn btn-ghost">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
              </div>
              <div
                tabIndex={0}
                className="dropdown-content card card-compact bg-stone-50 text-black z-[1] w-96 max-w-[90vw] p-1 shadow">
                <div className="card-body">
                  <div className="grid grid-cols-2 gap-2 pb-2">
                    <NextLink prefetch={false} href="/" className='flex flex-row items-center align-middle'>🏠 &nbsp; Homepage</NextLink>
                    <NextLink prefetch={false} href="/login" className='flex flex-row items-center align-middle'>👋 &nbsp; Login</NextLink>
                  </div>                  
                  <div className="flex items-center">
                    <div className="font-bold text-left flex-shrink">FACE ANALYSIS AI</div>
                    <div className="border-b border-gray-300 flex-grow ml-2"></div>
                  </div>
                  <div className="grid grid-cols-2 gap-2 pb-2">
                    <NextLink prefetch={false} href="/face_analyzer" className='flex flex-row items-center align-middle'>
                      <Image src="/menu/emojis-7.png" width={24} height={24} className="mr-1"/>
                    Face Shape Analyzer</NextLink>
                    <NextLink prefetch={false} href="/face_analyzer/eye_shape" className='flex flex-row items-center align-middle'>
                      <Image src="/menu/emojis-5.png" width={24} height={24} className="mr-1"/>
                    Eye Shape Analyzer</NextLink>
                    <NextLink prefetch={false} href="/skintone_analyzer" className='flex flex-row items-center align-middle'>
                      <Image src="/menu/emojis-8.png" width={24} height={24} className="mr-1"/>
                    Skintone Analyzer</NextLink>
                    <NextLink prefetch={false} href="/color_analyzer" className='flex flex-row items-center align-middle'>
                      <p className='mr-1 text-lg'>🎨</p>
                    Color Season Analyzer</NextLink>
                    {/* <NextLink prefetch={false} href="/color_analyzer" className='flex flex-row items-center align-middle'>🎨 &nbsp; Color Season Analyzer</NextLink> */}
                  </div>
                  <div className="flex items-center">
                    <div className="font-bold text-left flex-shrink">MAKEUP GUIDANCE</div>
                    <div className="border-b border-gray-300 flex-grow ml-2"></div>
                  </div>
                  <div className="grid grid-cols-2 gap-2 pb-2">
                    <NextLink prefetch={false} href="/makeup_recommendations/face_contour" className='flex flex-row items-center align-middle'>
                      <Image src="/menu/emojis-3.png" width={24} height={24} className="mr-1"/>
                    Face Contour Guide</NextLink>
                    <NextLink prefetch={false} href="/makeup_recommendations/nose_contour" className='flex flex-row items-center align-middle'>
                      <Image src="/menu/emojis-6.png" width={24} height={24} className="mr-1"/>
                    Nose Contour Guide</NextLink>
                    <NextLink prefetch={false} href="/makeup_recommendations/blush_placement" className='flex flex-row items-center align-middle'>
                      <Image src="/menu/emojis-4.png" width={24} height={24} className="mr-1"/>
                    Blush Placement Guide</NextLink>
                    <NextLink prefetch={false} href="/makeup_recommendations/eyebrows" className='flex flex-row items-center align-middle'>
                      <Image src="/menu/emojis-2.png" width={24} height={24} className="mr-1"/>
                    Eyebrow Shaping Guide</NextLink>
                    <NextLink prefetch={false} href="/makeup_recommendations/eyeliner" className='flex flex-row items-center align-middle'>
                      <Image src="/menu/emojis-1.png" width={24} height={24} className="mr-1"/>
                    Eyeliner Guide</NextLink>
                  </div>       
                  <div className="flex items-center">
                    <div className="font-bold text-left flex-shrink">GENERAL</div>
                    <div className="border-b border-gray-300 flex-grow ml-2"></div>
                  </div>           
                  <div className="grid grid-cols-2 gap-2">
                    <NextLink prefetch={false} href="/help" className='flex flex-row items-center align-middle'>🆘  &nbsp;Help Center</NextLink>
                    <NextLink prefetch={false} href="/blog" className='flex flex-row items-center align-middle'>📝  &nbsp;Blog</NextLink>
                    <NextLink prefetch={false} href="/pricing" className='flex flex-row items-center align-middle'>🏷️  &nbsp;Pricing</NextLink>
                  </div>
                </div>
              </div>
            </div>            
            <NextLink prefetch={false} href="/" className="btn btn-ghost normal-case text-xl pl-0 md:pl-4">StyleSense</NextLink>
            <ul className="menu menu-horizontal px-1 hidden md:inline-flex text-sm lg:text-base">
              <li>
                <details>
                  <summary>
                    Face Analysis AI
                  </summary>
                  <ul className="bg-base-100">
                    <li ><NextLink prefetch={false} href="/face_analyzer" className="break-words text-sm">Face Shape Analyzer</NextLink></li>
                    <li><NextLink prefetch={false} href="/face_analyzer/eye_shape" className="break-words text-sm">Eye Shape Analyzer <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 24 24" className="ml-2"><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm4.24 16L12 15.45 7.77 18l1.12-4.81-3.73-3.23 4.92-.42L12 5l1.92 4.53 4.92.42-3.73 3.23L16.23 18z"/></svg></NextLink></li>      
                    <li><NextLink prefetch={false} href="/skintone_analyzer" className="break-words text-sm">Skintone Analyzer</NextLink></li>
                    <li><NextLink prefetch={false} href="/color_analyzer" className="break-words text-sm">Color Season Analyzer <span className="text-xs">(in beta)</span></NextLink></li>
                  </ul>
                </details>
              </li>  
              <li>
                <details>
                  <summary>
                    Makeup Guidance
                  </summary>
                  <ul className="bg-base-100">
                    <li><NextLink prefetch={false} href="/makeup_recommendations/face_contour" className="break-words text-sm">Face Contour Guide</NextLink></li>
                    <li><NextLink prefetch={false} href="/makeup_recommendations/nose_contour" className="break-words text-sm">Nose Contour Guide <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 24 24" className="ml-2"><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm4.24 16L12 15.45 7.77 18l1.12-4.81-3.73-3.23 4.92-.42L12 5l1.92 4.53 4.92.42-3.73 3.23L16.23 18z"/></svg></NextLink></li>                            
                    <li><NextLink prefetch={false} href="/makeup_recommendations/blush_placement" className="break-words text-sm">Blush Placement Guide</NextLink></li>        
                    <li><NextLink prefetch={false} href="/makeup_recommendations/eyebrows" className="break-words text-sm">Eyebrow Shaping Guide</NextLink></li>        
                    <li><NextLink prefetch={false} href="/makeup_recommendations/eyeliner" className="break-words text-sm">Eyeliner Guide</NextLink></li>        
                  </ul>
                </details>
              </li>    
              <li>
                  <details>
                    <summary>Learn</summary>
                    <ul>
                      <li><NextLink prefetch={false} href="/blog">Blog</NextLink></li>
                      <li><NextLink prefetch={false} href="/help">Help Center</NextLink></li>                      
                    </ul>
                  </details>
                </li>         
              <li><NextLink prefetch={false} href="/pricing">Pricing</NextLink></li>                                                          
            </ul>            
          </div>
          <NavbarCTA />
        </div>
        <NavbarUserExtras />
        {/* {user && <><BuyCredits direct_to_checkout={true} trigger_source={"navbar"}/>
        <BuyCredits direct_to_credits={true} trigger_source={"profile-dropdown-credits-input"}/></>}
        {!userData.deleted ? null :
      <div className="bg-red-100 border-t border-b border-red-500 text-red-700 px-4 py-3 mb-5 w-full text-screen text-center" role="alert">
        <p className="font-bold">Your account is locked due to pending deletion</p>
        <p className="text-sm">You can restore it by clicking <button id="clear-upload" className="link" onClick={restoreUser}>here</button>.</p>
      </div>}         */}
      </>
    )
})

export default Navbar;